<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.guardianInformation') }}</h3>
      </template>

      <AppDescription
        :contentHolder="guardianInformationList"
        :value="value"
      ></AppDescription>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'ProfileDetailsGuardianInformationCard',
    components: {
      AppDescription
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      guardianInformationList: [
        {
          head: i18nHelper.getMessage('label.fullName'),
          body: {
            key: 'guardianFname'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.firstName'),
          body: {
            key: 'guardianFirstName'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.lastName'),
          body: {
            key: 'guardianLastName'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.dialCode'),
          body: {
            key: 'guardianMobileDialCode'
          },
          cols: 2
        },
        {
          head: i18nHelper.getMessage('label.mobile'),
          body: {
            key: 'guardianMobile'
          },
          cols: 2
        },
        {
          head: i18nHelper.getMessage('label.email'),
          body: {
            key: 'guardianEmail'
          },
          cols: 8
        },
        {
          head: i18nHelper.getMessage('label.sex'),
          body: {
            key: 'guardianGender'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.pronouns'),
          body: {
            key: 'guardianPronouns'
          },
          cols: 8
        },
        {
          head: i18nHelper.getMessage('label.relationship'),
          body: {
            key: 'guardianRelationship'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.relationshipOther'),
          body: {
            key: 'guardianRelationshipOther'
          },
          cols: 8
        }
      ]
    })
  };
</script>

<style></style>
