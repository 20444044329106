<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.accountInformation') }}</h3>
      </template>

      <AppDescription :contentHolder="accountInformationList" :value="value">
        <template slot="isActivated">
          <AppActivationStatusLabel
            :value="value.isActivated"
          ></AppActivationStatusLabel>
        </template>

        <template slot="isAccountDeactivated">
          <AppEnumLabel
            :value="Number(value.isAccountDeactivated)"
            :config="isAccountDeactivatedLabel.config"
          ></AppEnumLabel>
        </template>

        <template slot="isLoginBlocked">
          <AppSwitch
            :value="value.isLoginBlocked"
            :id="value.id"
            itemKey="isLoginBlocked"
            :disabled="
              checkAccessRight(permissionModule.USER, [
                permissionModule.USER_UPDATE
              ])
            "
            :switchActionsOnj="isLoginBlockedActions"
          ></AppSwitch>
        </template>
      </AppDescription>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import AppActivationStatusLabel from '@/views/components/bases/AppActivationStatusLabel';
  import AppSwitch from '@/views/components/bases/AppSwitch';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import {
    USER_UPDATE_USER_LOGIN_STATUS,
    USER_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'ProfileDetailsAccountInformationCard',
    components: {
      AppDescription,
      AppActivationStatusLabel,
      AppSwitch,
      AppEnumLabel
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      permissionModule: MODULE,
      permissionAction: ACTION,
      accountInformationList: [
        {
          head: i18nHelper.getMessage('label.status'),
          body: {
            key: 'isActivated',
            isComponent: true,
            slotName: 'isActivated'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.isAccountDeactivated'),
          body: {
            key: 'isAccountDeactivated',
            isComponent: true,
            slotName: 'isAccountDeactivated'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.isLoginBlocked'),
          body: {
            key: 'isLoginBlocked',
            isComponent: true,
            slotName: 'isLoginBlocked'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.registerDateTime'),
          body: {
            key: 'createdAt'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.lastLoginDateTime'),
          body: {
            key: 'lastLogin'
          },
          cols: 6
        }
      ],
      isAccountDeactivatedLabel: {
        config: {
          size: 'lg',
          enumKey: 'enumStatusAccountDeactivated',
          statusColor: (value) => {
            let color = {
              0: 'primary',
              1: 'danger'
            };

            return color[value];
          }
        }
      }
    }),
    computed: {
      isLoginBlockedActions() {
        return {
          module: 'user',
          state: 'updateUserLoginStatus',
          actionSet: USER_UPDATE_USER_LOGIN_STATUS,
          actionInitial: USER_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE,
          successAction: () => {
            this.refreshProfileDetails();
          },
          errorActionL: () => {},
          title: i18nHelper.getMessage('label.updateUserLoginStatus')
        };
      }
    },
    methods: {
      refreshProfileDetails() {
        this.$emit('refresh-profile-details');
      }
    }
  };
</script>

<style></style>
