<template>
  <div>
    <b-button
      size="sm"
      :disabled="disabled"
      :variant="`light-${profileVerificationStatusColor(value)}`"
      :class="{
        'cursor-default': disabled
      }"
      @click="onClickStatusAction(value)"
    >
      {{
        value == profileVerificationStatus.SUBMITTED
          ? $t('message.pendingVerificationClickToReview')
          : $t(`enumProfileVerificationStatus.${value}`)
      }}
    </b-button>
  </div>
</template>

<script>
  import { ProfileVerificationStatus } from '@/core/constants/enums';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'ProfileDetailsProfileVerificationStatusButton',
    props: {
      id: {
        type: Number
      },
      value: {
        type: String,
        default: ProfileVerificationStatus.PENDING
      }
    },
    data: () => ({
      profileVerificationStatus: ProfileVerificationStatus
    }),
    computed: {
      disabled() {
        return this.value != ProfileVerificationStatus.SUBMITTED;
      }
    },
    methods: {
      onClickStatusAction(value) {
        if (value == ProfileVerificationStatus.SUBMITTED) {
          this.$router.push({
            name: ROUTE_NAME.ACCOUNT_VERIFICATION_DETAILS,
            params: { id: this.id }
          });
        }
      },
      profileVerificationStatusColor(value) {
        let color = {
          [ProfileVerificationStatus.PENDING]: () => 'primary',
          [ProfileVerificationStatus.SUBMITTED]: () => 'warning',
          [ProfileVerificationStatus.APPROVED]: () => 'success',
          [ProfileVerificationStatus.REJECTED]: () => 'danger'
        };

        return color[value]();
      }
    }
  };
</script>

<style></style>
