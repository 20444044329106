<template>
  <div class="app-description">
    <!-- begin: Type Border -->
    <b-row
      v-if="type == descriptionType.BORDER"
      no-gutters
      class="app-description_row"
    >
      <template v-for="(item, index) in contentHolder">
        <b-col
          :key="index"
          :cols="item.cols"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
        >
          <div class="app-description_col-head">
            {{ item.head }}
          </div>
          <div class="app-description_col-body">
            <div v-if="item.body.slotName">
              <slot :name="item.body.slotName" />
            </div>
            <div v-else>
              {{ value[item.body.key] ? value[item.body.key] : '-' }}
            </div>
          </div>
        </b-col>
      </template>
    </b-row>
    <!-- end: Type Border -->

    <!-- begin: Type Default -->
    <div v-else-if="type == descriptionType.DEFAULT">
      <!-- begin: Title -->
      <h5 v-if="title" class="mb-4 text-capitalize font-weight-bold">
        {{ title }}
      </h5>
      <!-- end: Title -->

      <!-- begin: Description -->
      <template v-for="(item, index) in contentHolder">
        <b-row :key="index" no-gutters>
          <b-col
            cols="12"
            md="4"
            class="d-flex justify-md-content-between text-md-gray-600 p-3 font-weight-bolder font-md-weight-normal"
          >
            <div>
              {{ item.head }}
            </div>
            <div>:</div>
          </b-col>
          <b-col cols="12" md="8" class="p-3">
            <div v-if="item.body.slotName">
              <slot :name="item.body.slotName" />
            </div>
            <div v-else :class="item.body.class">
              {{ value[item.body.key] ? value[item.body.key] : '-' }}
            </div>
          </b-col>
        </b-row>
      </template>
      <!-- end: Description -->
    </div>
    <!-- end: Type Default -->
  </div>
</template>

<script>
  const DESCRIPTION_TYPE = Object.freeze({
    DEFAULT: 'default',
    BORDER: 'border'
  });

  export default {
    name: 'AppDescription',
    props: {
      contentHolder: {
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () => {}
      },
      type: {
        type: String,
        default: DESCRIPTION_TYPE.BORDER
      },
      title: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      descriptionType: DESCRIPTION_TYPE
    })
  };
</script>

<style lang="scss">
  .app-description {
    .app-description_table {
      .app-description_tr {
        .app-description_td {
          padding: 10px;
        }
      }
    }
    .app-description_row {
      .app-description_col-head {
        text-align: left;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;
        border: 1px solid var(--gray-200);
        padding: 12px 10px;
        color: var(--gray-600);
        background: var(--gray-100);
      }
      .app-description_col-body {
        text-align: left;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;
        border: 1px solid var(--gray-200);
        border-top: none;
        padding: 12px 10px;
        min-height: 60%;
      }
    }
  }
</style>
