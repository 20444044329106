<template>
  <b-card>
    <div class="d-flex justify-content-between mb-8">
      <h3 class="text-capitalize">{{ $t('label.overview') }}</h3>

      <!-- begin:: DropDown Actions -->
      <div class="dropdown-actions-container">
        <b-dropdown
          variant="primary"
          :text="$t('label.actions')"
          :disabled="isActionDropDownDisabled"
          :toggle-class="[{ 'cursor-default': isActionDropDownDisabled }]"
        >
          <b-dropdown-item
            v-if="isShowDeactivatedAccountItem"
            variant="danger"
            @click="onDeactivatedAccount"
          >
            {{ $t('label.deactivatedAccount') }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="isShowReactivateAccountItem"
            variant="primary"
            @click="onClickReactivateAccount"
          >
            {{ $t('label.reactivateAccount') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- end:: DropDown Actions -->
    </div>

    <div class="d-flex mb-8">
      <div @click="onProfileImageOpen">
        <b-avatar square size="72px" :src="profileImage"></b-avatar>
      </div>

      <div class="p-4">
        <div class="font-weight-bold h3">
          {{ userObj.fname }}
        </div>
        <div class="font-size-sm text-gray-600">
          {{ userObj.role }}
        </div>
      </div>
    </div>
    <div class="mb-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="i + index" class="mb-3 d-flex justify-content-between">
          <div class="font-weight-bolder text-capitalize">
            {{ $t(`label.${i.name}`) }}:
          </div>
          <div class="text-end text-gray-600">
            {{ userObj[i.key] ? userObj[i.key] : '-' }}
          </div>
        </div>
      </template>
    </div>

    <b-list-group class="profile-details_item-list-group">
      <template v-for="(item, index) in profileDetailItems">
        <b-list-group-item
          :key="index"
          button
          :disabled="
            item.name == 'guardianInformation' && userObj.role != 'Talent'
          "
          :active="value == item.key"
          @click="$emit('input', item.key)"
        >
          <b-row no-gutters>
            <b-col cols="2">
              <i class="icon mr-4" :class="item.icon"></i
            ></b-col>
            <b-col cols="auto" class="text-capitalize">
              {{ $t(`label.${item.name}`) }}</b-col
            >
          </b-row>
        </b-list-group-item>
      </template>
    </b-list-group>
    <ImageViewer
      v-if="showProfileImage"
      @onclose="showProfileImage = false"
      :value="profileImage"
    />
  </b-card>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_DEACTIVATE_USER_ACCOUNT,
    USER_REACTIVATE_USER_ACCOUNT,
    USER_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE,
    USER_INITIAL_REACTIVATE_USER_ACCOUNT_STATE
  } from '@/core/store/user.module';
  import ImageViewer from '@/views/components/bases/ImageViewer.vue';

  export default {
    name: 'ProfileDetailsOverviewCard',
    components: { ImageViewer },
    mixins: [commonMixin],
    props: {
      value: {
        type: Number,
        default: 1
      },
      userObj: {
        type: Object,
        default: () => {}
      },
      profileDetailItems: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      showProfileImage: false,
      basicInfoList: [
        {
          key: 'displayName',
          name: 'displayName'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        },
        {
          key: 'email',
          name: 'email'
        }
      ]
    }),
    computed: {
      profileImage() {
        return this?.userObj?.profile?.headshotFile?.mediaPath || '';
      },
      deactivateUserAccountComplete() {
        return this.$store.state.user.deactivateUserAccount.complete;
      },
      reactivateUserAccountComplete() {
        return this.$store.state.user.reactivateUserAccount.complete;
      },
      isShowDeactivatedAccountItem() {
        return !this.userObj.isAccountDeactivated;
      },
      isShowReactivateAccountItem() {
        return this.userObj.isAccountDeactivated;
      },
      isActionDropDownDisabled() {
        return !this.checkAccessRight(MODULE.USER, [ACTION.USER_UPDATE]);
      }
    },
    watch: {
      deactivateUserAccountComplete() {
        let response = this.$store.state.user.deactivateUserAccount;
        let title = i18nHelper.getMessage('label.deactivatedUserAccount');
        let initialStateAction = USER_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE;
        let successAction = () => {
          this.initialParentDetails();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      reactivateUserAccountComplete() {
        let response = this.$store.state.user.reactivateUserAccount;
        let title = i18nHelper.getMessage('label.reactivateUserAccount');
        let initialStateAction = USER_INITIAL_REACTIVATE_USER_ACCOUNT_STATE;
        let successAction = () => {
          this.initialParentDetails();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      onProfileImageOpen() {
        this.showProfileImage = true;
      },
      onProfileImageClose() {
        this.showProfileImage = false;
      },
      initialParentDetails() {
        this.$parent.initialProfileDetailsDetails();
      },
      onClickReactivateAccount() {
        this.reactivateUserAccount(this.userObj.id);
      },
      onDeactivatedAccount() {
        this.deactivateUserAccount(this.userObj.id);
      },
      reactivateUserAccount(id) {
        this.$store.dispatch(USER_REACTIVATE_USER_ACCOUNT, { id });
      },
      deactivateUserAccount(id) {
        this.$store.dispatch(USER_DEACTIVATE_USER_ACCOUNT, { id });
      }
    }
  };
</script>

<style></style>
