var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('label.accountVerification')))])]},proxy:true}])},[(_vm.value.profile)?_c('div',[_c('b-card-text',[_vm._l((_vm.accountVerificationInfoList),function(i,index){return [_c('div',{key:i + index,staticClass:"mb-5"},[_c('div',{staticClass:"font-weight-bolder text-capitalize mb-2"},[_vm._v(" "+_vm._s(_vm.$t(("label." + (i.name))))+": ")]),_c('div',{staticClass:"text-gray-600"},[(i.key == 'verificationStatus')?_c('div',[_c('ProfileDetailsProfileVerificationStatusButton',{attrs:{"id":_vm.value.id,"value":_vm.value.profile[i.key]}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.value.profile[i.key] ? _vm.value.profile[i.key] : '-')+" ")])])])]}),(
          _vm.value.profile.verificationStatus ==
          _vm.profileVerificationStatus.APPROVED
        )?_c('div',{staticClass:"separator separator-solid my-6"}):_vm._e(),(
          ['talent_agency', 'production_corporate_agency'].includes(_vm.userRole)
        )?_c('div',{staticClass:"mb-8 d-flex justify-content-between"},[_c('div',{staticClass:"font-weight-bolder text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("label.companyUniqueCode"))+": ")]),_c('div',{staticClass:"text-end text-gray-600"},[_vm._v(" "+_vm._s(_vm.value.companyCode)+" ")])]):_vm._e(),_vm._l((_vm.accountVerificationDocuments),function(i,index){return [(
            i.displayInRole.includes(_vm.userRole) &&
            _vm.value.profile.verificationStatus ==
              _vm.profileVerificationStatus.APPROVED
          )?_c('div',{key:index,staticClass:"mb-8"},[_c('div',{staticClass:"font-weight-bolder text-capitalize mb-2"},[_vm._v(" "+_vm._s(_vm.$t(("label." + (i.name))))+": ")]),_c('div',{staticClass:"text-gray-600"},[(i.key == 'verificationFile')?_c('div',[(_vm.value.profile.verificationFile)?_c('div',[_vm._l((_vm.value.profile
                    .verificationFile),function(item,verificationFileIndex){return [_c('div',{key:verificationFileIndex,staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('i',{staticClass:"flaticon-attachment mr-4"}),_c('span',{staticClass:"mb-0 text-capitalize"},[_vm._v(_vm._s(((_vm.$t('label.document')) + " " + (verificationFileIndex + 1))))])]),_c('AppButtonDocumentView',{attrs:{"attachmentUrl":item.mediaPath}})],1)]})],2):_c('div',[_vm._v(" "+_vm._s(_vm.$t('message.thereAreNoDocumentsToShow'))+" ")])]):(i.key == 'gestureImage')?_c('div',[(_vm.value.profile.gestureImage)?_c('AppMediaViewer',{staticClass:"bg-secondary",attrs:{"value":_vm.value.profile.gestureImage}}):_c('div',[_vm._v(_vm._s(_vm.$t('message.thereAreNoImagesToShow')))])],1):(i.key == 'gestureUserUploaded')?_c('div',[(_vm.value.profile.verificationFile[0])?_c('AppMediaViewer',{attrs:{"value":_vm.value.profile.verificationFile[0]}}):_c('div',[_vm._v(_vm._s(_vm.$t('message.thereAreNoImagesToShow')))])],1):_vm._e()])]):_vm._e()]})],2)],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t('message.thereIsNoAccountVerificationApplied'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }