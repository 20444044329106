<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.personalInformation') }}</h3>
      </template>

      <AppDescription
        :contentHolder="personalInformationNewList"
        :value="value"
      >
      </AppDescription>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'ProfileDetailsPersonalInformationCard',
    components: {
      AppDescription
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      personalInformationList: [
        {
          head: i18nHelper.getMessage('label.firstName'),
          body: {
            key: 'firstName'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.lastName'),
          body: {
            key: 'lastName'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.dateOfBirth'),
          body: {
            key: 'dob'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.dialCode'),
          body: {
            key: 'mobileDialCode'
          },
          cols: 2
        },
        {
          head: i18nHelper.getMessage('label.mobile'),
          body: {
            key: 'mobile'
          },
          cols: 2
        },
        {
          head: i18nHelper.getMessage('label.email'),
          body: {
            key: 'email'
          },
          cols: 8
        },
        {
          head: i18nHelper.getMessage('label.countryOfResidence'),
          body: {
            key: 'countryId'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.sex'),
          body: {
            key: 'gender'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.pronouns'),
          body: {
            key: 'pronouns'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.description'),
          body: {
            key: 'description'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.descriptionOther'),
          body: {
            key: 'descriptionOther'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.noOfTalents'),
          body: {
            key: 'talentsNo'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.projectType'),
          body: {
            key: 'projectTypeIds'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.projectRole'),
          body: {
            key: 'projectRoleIds'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.companyUniqueCode'),
          body: {
            key: 'companyCode'
          },
          cols: 12
        }
      ]
    }),
    computed: {
      personalInformationNewList() {
        let newList = [];

        if (
          ['talent_agency', 'production_corporate_agency'].includes(
            this.userRole
          )
        ) {
          newList = this.personalInformationList;
        } else {
          newList = this.personalInformationList.filter(
            (x) => x.body.key != 'companyCode'
          );
        }
        return newList;
      },
      userRole() {
        return this.value.role
          ? this.value.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : null;
      }
    }
  };
</script>

<style></style>
