<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.profile') }}</h3>
      </template>

      <AppDescription
        v-if="value.profile"
        :contentHolder="content"
        :value="value.profile"
      >
        <!-- begin: CV File -->
        <template slot="cvFile">
          <div v-if="value.profile.cvFile" class="px-4">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <i class="flaticon-attachment mr-4"></i>
                <span class="mb-0 text-capitalize">{{
                  $t('label.curriculumVitae')
                }}</span>
              </div>

              <AppButtonDocumentView
                :attachmentUrl="value.profile.cvFile.mediaPath"
              ></AppButtonDocumentView>
            </div>
          </div>
          <div v-else>{{ $t('message.thereAreNoDocumentsToShow') }}</div>
        </template>
        <!-- end: CV File -->

        <!-- begin: Intro Video -->
        <template slot="introVideoFile">
          <div
            v-if="value.profile.introVideoFile"
            class="intro-video-file__video-wrapper text-center"
          >
            <video
              controls
              :src="value.profile.introVideoFile.mediaPath"
              class="max-w-100 max-h-500px"
            ></video>
          </div>
          <div v-else>{{ $t('message.thereAreNoVideoToShow') }}</div>
        </template>
        <!-- end: Intro Video -->

        <!-- begin: Gallery File -->
        <template slot="galleryFile">
          <AppMediaViewer
            v-if="value.profile.galleryFile"
            :value="value.profile.galleryFile"
          >
          </AppMediaViewer>

          <div v-else>{{ $t('message.thereAreNoItemsToShow') }}</div>
        </template>
        <!-- end: Gallery File -->

        <!-- begin: Company Logo File -->
        <template slot="companyLogoFile">
          <div v-if="value.profile.companyLogoFile" class="px-4">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <i class="flaticon-attachment mr-4"></i>
                <span class="mb-0 text-capitalize">{{
                  $t('label.companyLogo')
                }}</span>
              </div>

              <AppButtonDocumentView
                :attachmentUrl="value.profile.companyLogoFile.mediaPath"
              ></AppButtonDocumentView>
            </div>
          </div>
          <div v-else>{{ $t('message.thereAreNoDocumentsToShow') }}</div>
        </template>
        <!-- end: Company Logo File -->

        <!-- begin: Company Showreel Video File -->
        <template slot="companyShowreelVideoFile">
          <AppMediaViewer
            v-if="value.profile.companyShowreelVideoFile"
            :value="value.profile.companyShowreelVideoFile"
          >
          </AppMediaViewer>

          <div v-else>{{ $t('message.thereAreNoVideoToShow') }}</div>
        </template>
        <!-- end: Company Showreel Video File -->

        <!-- begin: Company Showreel Image File -->
        <template slot="companyShowreelImageFile">
          <AppMediaViewer
            v-if="value.profile.companyShowreelImageFile"
            :value="value.profile.companyShowreelImageFile"
          >
          </AppMediaViewer>

          <div v-else>{{ $t('message.thereAreNoImagesToShow') }}</div>
        </template>
        <!-- end: Company Showreel Image File -->
      </AppDescription>

      <div v-else>
        {{ $t('message.thereAreNoProfileToShow') }}
      </div>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'ProfileDetailsProfileCard',
    components: {
      AppDescription,
      AppButtonDocumentView,
      AppMediaViewer
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      talentProfileList: [
        {
          head: i18nHelper.getMessage('label.displayName'),
          body: {
            key: 'displayName'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.bio'),
          body: {
            key: 'bio'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.introSummary'),
          body: {
            key: 'introSummary'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.curriculumVitae'),
          body: {
            key: 'cv'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.curriculumVitaeFiles'),
          body: {
            key: 'cvFile',
            isComponent: true,
            slotName: 'cvFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.onScreenAge'),
          body: {
            key: 'ageToPlay'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.height'),
          body: {
            key: 'height'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.weight'),
          body: {
            key: 'weight'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.hairColor'),
          body: {
            key: 'hairColor'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.hairLength'),
          body: {
            key: 'hairLength'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.hairType'),
          body: {
            key: 'hairType'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.eyeColor'),
          body: {
            key: 'eyeColor'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.skinColor'),
          body: {
            key: 'skinColor'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.ethnicity'),
          body: {
            key: 'ethnicity'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.race'),
          body: {
            key: 'race'
          },
          cols: 4
        },
        {
          head: i18nHelper.getMessage('label.languagesSpoken'),
          body: {
            key: 'languagesSpoken'
          },
          cols: 8
        },
        {
          head: i18nHelper.getMessage('label.introVideo'),
          body: {
            key: 'introVideoFile',
            isComponent: true,
            slotName: 'introVideoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.gallery'),
          body: {
            key: 'galleryFile',
            isComponent: true,
            slotName: 'galleryFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.location'),
          body: {
            key: 'location'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.skills'),
          body: {
            key: 'skills'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.tvOnlineRate'),
          body: {
            key: 'tvOnlineRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.featureFilmRate'),
          body: {
            key: 'featureFilmRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.commercialRate'),
          body: {
            key: 'commercialRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.varietyRate'),
          body: {
            key: 'varietyRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.studentProjectRate'),
          body: {
            key: 'studentProjectRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.corporateRate'),
          body: {
            key: 'corporateRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.publicServiceAnnouncementRate'),
          body: {
            key: 'publicServiceAnnouncementRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.voiceoverRate'),
          body: {
            key: 'voiceoverRate'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.facebook'),
          body: {
            key: 'facebook'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.instagram'),
          body: {
            key: 'instagram'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.twitter'),
          body: {
            key: 'twitter'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.linkedin'),
          body: {
            key: 'linkedin'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.youtube'),
          body: {
            key: 'youtube'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.tiktok'),
          body: {
            key: 'tiktok'
          },
          cols: 6
        }
      ],
      freelancerProfileList: [
        {
          head: i18nHelper.getMessage('label.displayName'),
          body: {
            key: 'displayName'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.bio'),
          body: {
            key: 'bio'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.introSummary'),
          body: {
            key: 'introSummary'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.curriculumVitae'),
          body: {
            key: 'cv'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.curriculumVitaeFiles'),
          body: {
            key: 'cvFile',
            isComponent: true,
            slotName: 'cvFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.introVideo'),
          body: {
            key: 'introVideoFile',
            isComponent: true,
            slotName: 'introVideoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.gallery'),
          body: {
            key: 'galleryFile',
            isComponent: true,
            slotName: 'galleryFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.listProjects'),
          body: {
            key: 'listProjects'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.websiteLink'),
          body: {
            key: 'websiteLink'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.facebook'),
          body: {
            key: 'facebook'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.instagram'),
          body: {
            key: 'instagram'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.twitter'),
          body: {
            key: 'twitter'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.linkedin'),
          body: {
            key: 'linkedin'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.youtube'),
          body: {
            key: 'youtube'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.tiktok'),
          body: {
            key: 'tiktok'
          },
          cols: 6
        }
      ],
      talentAgencyProfileList: [
        {
          head: i18nHelper.getMessage('label.companyLogo'),
          body: {
            key: 'companyLogoFile',
            isComponent: true,
            slotName: 'companyLogoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.talentsNo'),
          body: {
            key: 'talentsNo'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.companyWriteUp'),
          body: {
            key: 'companyWriteUp'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.companyShowreelVideo'),
          body: {
            key: 'companyShowreelVideoFile',
            isComponent: true,
            slotName: 'companyShowreelVideoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.companyShowreelImage'),
          body: {
            key: 'companyShowreelImageFile',
            isComponent: true,
            slotName: 'companyShowreelImageFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.listProjects'),
          body: {
            key: 'listProjects'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.websiteLink'),
          body: {
            key: 'websiteLink'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.facebook'),
          body: {
            key: 'facebook'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.instagram'),
          body: {
            key: 'instagram'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.twitter'),
          body: {
            key: 'twitter'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.linkedin'),
          body: {
            key: 'linkedin'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.youtube'),
          body: {
            key: 'youtube'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.tiktok'),
          body: {
            key: 'tiktok'
          },
          cols: 6
        }
      ],
      productionHouseProfileList: [
        {
          head: i18nHelper.getMessage('label.companyLogo'),
          body: {
            key: 'companyLogoFile',
            isComponent: true,
            slotName: 'companyLogoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.talentsNo'),
          body: {
            key: 'talentsNo'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.companyWriteUp'),
          body: {
            key: 'companyWriteUp'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.companyShowreelVideo'),
          body: {
            key: 'companyShowreelVideoFile',
            isComponent: true,
            slotName: 'companyShowreelVideoFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.companyShowreelImage'),
          body: {
            key: 'companyShowreelImageFile',
            isComponent: true,
            slotName: 'companyShowreelImageFile'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.listProjects'),
          body: {
            key: 'listProjects'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.websiteLink'),
          body: {
            key: 'websiteLink'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.facebook'),
          body: {
            key: 'facebook'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.instagram'),
          body: {
            key: 'instagram'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.twitter'),
          body: {
            key: 'twitter'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.linkedin'),
          body: {
            key: 'linkedin'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.youtube'),
          body: {
            key: 'youtube'
          },
          cols: 6
        },
        {
          head: i18nHelper.getMessage('label.tiktok'),
          body: {
            key: 'tiktok'
          },
          cols: 6
        }
      ]
    }),
    computed: {
      content() {
        let role = this.value.role
          ? this.value.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : 'talent';

        let list = {
          talent: () => this.talentProfileList,
          freelancer: () => this.freelancerProfileList,
          talent_agency: () => this.talentAgencyProfileList,
          production_corporate_agency: () => this.productionHouseProfileList
        };

        return list[role]();
      }
    }
  };
</script>

<style lang="scss">
  .intro-video-file__video-wrapper {
    background-color: #000000;
  }
</style>
