<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!-- begin: Overview -->
        <ProfileDetailsOverviewCard
          v-model="selectedProfileDetailItem"
          :userObj="userObj"
          :profileDetailItems="displayableProfileDetailItems"
          class="mb-6"
        ></ProfileDetailsOverviewCard>
        <!-- begin: Overview -->

        <!-- begin: Account Verification Card -->
        <ProfileDetailsAccountVerificationCard
          :value="userObj"
        ></ProfileDetailsAccountVerificationCard>
        <!-- End: Account Verification Card -->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <template v-for="(item, index) in displayableProfileDetailItems">
          <component
            v-if="selectedProfileDetailItem == item.key"
            :key="index"
            :is="item.component"
            :value="userObj"
            :id="id"
            :tableId="id"
            :logType="auditLogType"
            showAuditLogsCardHeader
            @refresh-profile-details="refreshProfileDetailsAction"
          >
          </component>
        </template>
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import ProfileDetailsOverviewCard from '@/views/components/profile-details/ProfileDetailsOverviewCard';
  import ProfileDetailsPersonalInformationCard from '@/views/components/profile-details/ProfileDetailsPersonalInformationCard';
  import ProfileDetailsGuardianInformationCard from '@/views/components/profile-details/ProfileDetailsGuardianInformationCard';
  import ProfileDetailsAccountInformationCard from '@/views/components/profile-details/ProfileDetailsAccountInformationCard';
  import ProfileDetailsProfileCard from '@/views/components/profile-details/ProfileDetailsProfileCard';
  import ProfileDetailsAccountVerificationCard from '@/views/components/profile-details/ProfileDetailsAccountVerificationCard';
  import AuditLogsCard from '@/views/components/audit-logs/AuditLogsCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { AUDIT_LOG_TABLE_NAME } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_GET_USER,
    USER_INITIAL_GET_USER_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'ProfileDetailsDetails',
    mixins: [commonMixin],
    components: {
      ProfileDetailsOverviewCard,
      ProfileDetailsPersonalInformationCard,
      ProfileDetailsGuardianInformationCard,
      ProfileDetailsAccountInformationCard,
      ProfileDetailsProfileCard,
      ProfileDetailsAccountVerificationCard,
      AuditLogsCard
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.USER,
      permissionAction: ACTION,
      id: null,
      userObj: {},
      selectedProfileDetailItem: 1,
      profileDetailItems: [
        {
          name: 'personalInformation',
          key: 1,
          icon: 'fas fa-user',
          component: 'ProfileDetailsPersonalInformationCard'
        },
        {
          name: 'guardianInformation',
          key: 2,
          icon: 'fas fa-user-friends',
          component: 'ProfileDetailsGuardianInformationCard'
        },
        {
          name: 'accountInformation',
          key: 3,
          icon: 'fas fa-user-cog',
          component: 'ProfileDetailsAccountInformationCard'
        },
        {
          name: 'profile',
          key: 4,
          icon: 'far fa-address-card',
          component: 'ProfileDetailsProfileCard'
        },
        {
          name: 'logs',
          key: 5,
          icon: 'flaticon2-list-3',
          component: 'AuditLogsCard'
        }
      ],
      auditLogType: AUDIT_LOG_TABLE_NAME.USERS
    }),
    computed: {
      userComplete() {
        return this.$store.state.user.user.complete;
      },
      displayableProfileDetailItems() {
        let newList = this.profileDetailItems;
        let data = this.userObj;

        if (!data.guardianRelationship) {
          newList = newList.filter((x) => x.key != 2);
        }

        return newList;
      }
    },
    watch: {
      userComplete() {
        let response = this.$store.state.user.user;
        let title = i18nHelper.getMessage('label.getProfileDetails');
        let initialStateAction = USER_INITIAL_GET_USER_STATE;
        let successAction = (response) => {
          this.importUserObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialProfileDetailsDetails();
    },
    methods: {
      refreshProfileDetailsAction() {
        this.getUser(this.id);
      },
      importUserObj(response) {
        let data = response.data;

        this.userObj = data;
      },
      getUser(id) {
        this.$store.dispatch(USER_GET_USER, {
          id
        });
      },
      initialProfileDetailsDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.profileDetails'),
              route: { name: ROUTE_NAME.PROFILE_DETAILS }
            },
            { title: i18nHelper.getMessage('label.profileDetailsDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getUser(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss">
  .profile-details_item-list-group {
    .list-group-item.list-group-item-action.active {
      background: unset !important;
      border-color: unset !important;
      color: var(--primary);
      .icon {
        color: var(--primary);
      }
    }
  }
</style>
