<template>
  <b-card>
    <template #header>
      <h3 class="text-capitalize">{{ $t('label.accountVerification') }}</h3>
    </template>

    <div v-if="value.profile">
      <b-card-text>
        <template v-for="(i, index) in accountVerificationInfoList">
          <div :key="i + index" class="mb-5">
            <div class="font-weight-bolder text-capitalize mb-2">
              {{ $t(`label.${i.name}`) }}:
            </div>

            <div class="text-gray-600">
              <div v-if="i.key == 'verificationStatus'">
                <ProfileDetailsProfileVerificationStatusButton
                  :id="value.id"
                  :value="value.profile[i.key]"
                ></ProfileDetailsProfileVerificationStatusButton>
              </div>
              <div v-else>
                {{ value.profile[i.key] ? value.profile[i.key] : '-' }}
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="
            value.profile.verificationStatus ==
            profileVerificationStatus.APPROVED
          "
          class="separator separator-solid my-6"
        ></div>

        <!-- begin: Company Unique Code -->
        <div
          v-if="
            ['talent_agency', 'production_corporate_agency'].includes(userRole)
          "
          class="mb-8 d-flex justify-content-between"
        >
          <div class="font-weight-bolder text-capitalize">
            {{ $t(`label.companyUniqueCode`) }}:
          </div>
          <div class="text-end text-gray-600">
            {{ value.companyCode }}
          </div>
        </div>
        <!-- end: Company Unique Code -->

        <template v-for="(i, index) in accountVerificationDocuments">
          <div
            v-if="
              i.displayInRole.includes(userRole) &&
              value.profile.verificationStatus ==
                profileVerificationStatus.APPROVED
            "
            :key="index"
            class="mb-8"
          >
            <div class="font-weight-bolder text-capitalize mb-2">
              {{ $t(`label.${i.name}`) }}:
            </div>

            <div class="text-gray-600">
              <div v-if="i.key == 'verificationFile'">
                <div v-if="value.profile.verificationFile">
                  <template
                    v-for="(item, verificationFileIndex) in value.profile
                      .verificationFile"
                  >
                    <div
                      :key="verificationFileIndex"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <i class="flaticon-attachment mr-4"></i>
                        <span class="mb-0 text-capitalize">{{
                          `${$t('label.document')} ${verificationFileIndex + 1}`
                        }}</span>
                      </div>

                      <AppButtonDocumentView
                        :attachmentUrl="item.mediaPath"
                      ></AppButtonDocumentView>
                    </div>
                  </template>
                </div>
                <div v-else>
                  {{ $t('message.thereAreNoDocumentsToShow') }}
                </div>
              </div>
              <div v-else-if="i.key == 'gestureImage'">
                <AppMediaViewer
                  v-if="value.profile.gestureImage"
                  :value="value.profile.gestureImage"
                  class="bg-secondary"
                >
                </AppMediaViewer>

                <div v-else>{{ $t('message.thereAreNoImagesToShow') }}</div>
              </div>
              <div v-else-if="i.key == 'gestureUserUploaded'">
                <AppMediaViewer
                  v-if="value.profile.verificationFile[0]"
                  :value="value.profile.verificationFile[0]"
                >
                </AppMediaViewer>

                <div v-else>{{ $t('message.thereAreNoImagesToShow') }}</div>
              </div>
            </div>
          </div>
        </template>
      </b-card-text>
    </div>
    <div v-else>
      {{ $t('message.thereIsNoAccountVerificationApplied') }}
    </div>
  </b-card>
</template>

<script>
  import ProfileDetailsProfileVerificationStatusButton from '@/views/components/profile-details/ProfileDetailsProfileVerificationStatusButton';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import { ProfileVerificationStatus } from '@/core/constants/enums';

  export default {
    name: 'ProfileDetailsAccountVerificationCard',
    components: {
      ProfileDetailsProfileVerificationStatusButton,
      AppButtonDocumentView,
      AppMediaViewer
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      accountVerificationInfoList: [
        {
          key: 'verificationStatus',
          name: 'status'
        },
        {
          key: 'verificationRemark',
          name: 'remark'
        },
        {
          key: 'verificationSubmittedAt',
          name: 'submittedAt'
        }
      ],
      accountVerificationDocuments: [
        {
          key: 'gestureImage',
          name: 'gestureImage',
          displayInRole: ['talent', 'freelancer']
        },
        {
          key: 'gestureUserUploaded',
          name: 'userUploaded',
          displayInRole: ['talent', 'freelancer']
        },
        {
          key: 'verificationFile',
          name: 'supportingDocuments',
          displayInRole: ['talent_agency', 'production_corporate_agency']
        }
      ],
      profileVerificationStatus: ProfileVerificationStatus
    }),
    computed: {
      userRole() {
        return this.value.role
          ? this.value.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : null;
      }
    }
  };
</script>

<style></style>
